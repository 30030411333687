@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
body {
    font-family: "Roboto Condesed", sans-serif;
}

:root {
    --primary-white: #fff;
    --primary-orange-lite: rgb(243, 197, 154);
    --primary-black: rgb(0, 0, 0);
    --primary-orange: #3c65cc;
    --primary-dark: rgba(255, 255, 255, 0);
    --primary-red: #ef4035;
    --primary-hover-red: #2e5fd9;
    --primary-gray-lite: rgba(133, 141, 153, 0.199);
    --primary-gray-lite-2: rgba(133, 141, 153, 0.301);
    --primary-gray-dark: #474747;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/**************************** NAV BAR **********************************/

nav {
    z-index: 2;
    opacity: 0.8;
}

.logo {
    margin-top: 0.5rem;
    width: 10.25rem;
}

@media(max-width:768px) {
    .logo {
        width: 8rem;
        margin-top: 0.8rem;
    }
}


/*------------ Menu Links -------------*/

nav a.nav-link {
    letter-spacing: 0.0375rem;
    cursor: pointer;
}

nav ul li {
    text-transform: uppercase;
}

.navbar-nav>li>a {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
    color: var(--primary-white) !important;
    border-top: 0.1875rem solid var(--primary-dark);
}

.navbar-brand {
    max-width: 17.25rem;
    padding: 0 !important;
    height: 4rem !important;
}

@media(max-width:768px) {
    .navbar-brand {
        width: 7rem;
    }
}

.navbar-nav>li>a.active,
.navbar-nav>li>a.active:focus {
    border-top: 0.1875rem solid var(--primary-orange);
}

.navbar-nav>li>a:hover {
    border-top: 0.1875rem solid var(--primary-orange);
}

.FontAwesomeIcon {
    color: var(--primary-white);
}


/**************************** HEADER **********************************/

.header-wraper {
    position: relative;
    /*    background: url(header.mp4) no-repeat;*/
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 80vh;
    background-color: var(--primary-gray-lite);
}

video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.main-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Roboto Condesed", sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.main-info h1 {
    color: var(--primary-black);
    text-transform: uppercase;
}

@media(max-width:768px) {
    .main-info h1 {
        font-size: 1.7rem !important;
    }
}


/*------------------------ TYPED TEXT -----------------------------*/

.color-white {
    color: white !important;
}

.typed-text {
    font-size: 2rem;
    color: var(--primary-black);
}

@media(max-width:768px) {
    .typed-text {
        font-size: 1.3rem !important;
    }
}


/*------------------------ Header Main Offer button -----------------------------*/

.btn-main-offer {
    border: 1px solid var(--primary-orange);
    text-transform: uppercase;
    background-color: var(--primary-orange);
    color: var(--primary-white);
    font-weight: 500;
    border-radius: 10px;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    margin: 2rem;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.btn-main-offer:hover {
    text-decoration: none;
    background-color: var(--primary-hover-red);
    color: var(--primary-white);
    transition: 0.2s ease-in-out;
}


/*------------------------ Particles -----------------------------*/

.tsparticles-canvas-el {
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1;
}

@media(max-width:768px) {
    .particles-canvas {
        display: none;
    }
}


/*------------------------ TimeLine ------------------------------*/

.experience-heading {
    height: 15vh;
    padding: 3rem;
}

.timeline {
    background-color: var(--primary-gray-lite);
}


/*------------------------- About ------------------------------*/

.about {
    background-color: var(--primary-gray-dark);
    color: var(--primary-white);
    padding: 2rem;
    font-family: "Roboto Condesed", sans-serif;
}


/*------------------------- Skills ------------------------------*/

.skills {
    background-color: var(--primary-gray-dark);
    color: var(--primary-white);
    padding: 2rem;
    font-family: "Roboto Condesed", sans-serif;
}

.skill-image {
    height: 5rem;
    width: 5rem;
    margin: 0.5rem;
    display: inline-block;
}

@media(max-width:768px) {
    .terminal {
        height: 250px;
        font-size: 12px;
    }
}

@media(max-width:768px) {
    .terminal-typed {
        font-size: 12px;
    }
}


/*------------------------- Contact ------------------------------*/

.contact {
    background-color: var(--primary-gray-lite);
    color: var(--primary-black);
    padding: 2rem;
    font-family: "Roboto Condesed", sans-serif;
}

.submit-button {
    margin-top: 20px;
    float: right;
    width: 100%;
    height: 2.5rem;
}


/*------------------------- Footer ------------------------------*/

.footer {
    background-color: var(--primary-gray-dark);
    color: var(--primary-white);
    padding: 2rem;
    font-family: "Roboto Condesed", sans-serif;
}

.footer-nav {
    display: flex;
    list-style: none;
}

.footer-nav>li {
    margin-left: 10px;
}

.social-logo>a {
    margin-left: 10px;
}

@media(max-width:768px) {
    .social-logo {
        text-align: center;
    }
    .footer-nav {
        width: auto
    }
    .footer-nav>li {
        text-align: center;
    }
}


/*------------------------- Heights of components for desktop ------------------------------*/

@media(min-width: 1000px) {
    .about {
        height: 35rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .skills {
        height: 35rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .contact {
        height: 45rem;
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .contact-elements {
        margin-bottom: 2rem;
    }
    .footer {
        height: 4rem;
        padding: 1rem;
    }
    .contact .row {
        margin-bottom: 1rem;
        width: 50%;
        display: flex;
        justify-content: center;
        align-content: center;
        align-self: center;
        margin: 0 auto;
        margin-top: 1rem;
    }
    .contact .submit-button {
        width: 39rem;
        height: 2.5rem;
        margin-bottom: 2rem;
    }
}


/*------------------------- Heights of components for Mobile ------------------------------*/

@media(max-width: 880px) {
    .contact .row {
        margin-bottom: 1rem;
    }
    .contact .submit-button {
        width: 92%;
        height: 2.5rem;
        margin: 0 auto;
        margin-top: 2rem;
    }
}


/*------------------------- Hide Arrows from number input ----------------------------------*/


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}


/*------------------------- Hide Arrows from number input ----------------------------------*/
